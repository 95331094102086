const enUs = {
    当前设备:"Current device",
    未命名设备:"Unnamed",
    设备列表:"Device",
    设备分享:"Device sharing",
    设备续费:"Device renewal",
    流量查询:"Traffic query",
    电子围栏:"Security fence",
    账号认证:"Account veri",
    语言切换:"Language switch",
    系统设置:"System settings",
    注销账号:"Delete account",
    常见问题:"FAQ",
    当前版本:"Current version",
    退出登录:"Sign out",
    设备信息:"Device Information",
    设备设置:"device settings",
    设备指令:"Device orders",
    设备名称或IMEI:"Device name or IMEI",
    搜索:"Search",
    分组筛选:"Froup filter",
    请选择设备分组:"Select a group",
    位置全览:"Location overview",
    左滑设备信息操作设备:"Swipe left to set",
    没有更多了:"no more",
    解绑:"untie",
    编辑:"edit",
    取消:"Cancel",
    请选择分组:"Select a group",
    确认:"Confirmation",
    暂无数据:"No data",
    操作确认:"Confirmation",
    确认解绑此设备:"Confirm to unbind this device",
    在线:"Online",
    离线:"Offline",
    停留:" Stay",
    在线运动中:"Online in motion",
    电量:"Power",
    全部:"All",
    数据更新成功:"Data update is successful",
    确认修改:"Confirm the changes",
    消息推送:"Message push",
    修改密码:"change Password",
    旧密码:"Old Password",
    请输入旧密码未设置密码可留空:"Please enter the old password, you can leave empty without setting the password",
    数字字母或字符:"6-12 digits, letters or characters",
    新密码:"New Password",
    请填写新密码:"Please fill in the new password",
    确认密码:"Confirm Password",
    请再次输入密码:"Please enter the password again",
    请填写确认密码:"Please fill in the confirmation password",
    提交:"submit",
    密码应是数字字母或字符:"The password should be 6-12 digits, letters or characters",
    两次输入的密码不一致请检查重试:"The passwords in two inputs are inconsistent, please check the re -examine",
    密码修改成功:"Password reset complete",
    认证成功:"Authentication success",
    认证失败:"Authentication failed",
    加载中:"Loading...",
    刷新结果:"Refresh the result",
    注销账号提示:"Delete account tips",
    账号注销提示1:"Account cancellation is an irreplaceable operation, and once the cancellation will bring a lot of inconvenience to your after -sales rights protection and information inquiry, please handle it carefully. If you still want to cancel, please make sure you have backup the relevant data and fully read it, understand and agree to the following items:",
    账号注销提示2:"You need to unbind all the equipment for the account you apply for a cancellation",
    账号注销提示3:"After the account of the account is canceled, your platform service agreement will be announced, and this platform will stop providing you with services, so your account may have the following consequences：",
    账号注销提示4:"1.The current account is recovered, you will be unable to log in and use this account。",
    账号注销提示5:"2.Your account information and historical data will be deleted or processed anonymously, and you cannot access, query or download, and copy. However, it will continue to be preserved for a period of time at the allowable scope of laws and regulations。",
    账号注销提示6:"In addition, the cancellation account does not mean that the account behavior or related responsibilities before canceling the cancellation or related responsibilities will be exempted or reduced; at the same time, if you choose to cancel or the losses caused by the selection of related matters, you need to choose to cancel it.",
    账号注销提示7:"I have read the account to cancel the prompt and start canceling",
    账号注销成功:"Successive account cancellation",
    用户使用协议:"User use protocol",
    隐私保护协议:"Privacy Protection Agreement",
    结束时间:"End Time",
    选择时间:"Select time",
    开始时间:"Starting time",
    开关:"switch",
    重复:"repeat",
    请选择重复时间:"Please select repeat time",
    周一:"Monday",
    周二:"Tuesday",
    周三:"Wednesday",
    周四:"Thursday",
    周五:"Friday",
    周六:"Saturday",
    周日:"Sunday",
    请输入:"please enter",
    设备名称:"Device name",
    支付项目:"Payment item",
    微信支付:"WeChat payment",
    支付成功:"payment successful",
    支付失败:"Payment failure",
    请选择支付项:"Please select payment items",
    支付确认:"Payment confirmation",
    订单支付成功:"Successful order payment? ",
    发送中:"sending...",
    发送成功:"Sent successfully",
    指令发送成功:"Successive instruction sending",
    重启:"Restart",
    显示类型:"Display type",
    请选择显示类型:"Please select the display type",
    清空:"Empty",
    下拉列表刷新数据左滑通知信息删除:"Refresh down, remove the left slide",
    删除:"delete",
    SOS报警:"SOS alarm",
    围栏报警:"Fence alarm",
    位移报警:"Displacement alarm",
    静止报警:"Stay alarm",
    低电报警:"Low -electric alarm",
    震动报警:"Vibration alarm",
    超速报警:"Speed alarm",
    上线报警:"Online alarm",
    离线报警:"Offline alarm",
    开始充电:"Start charging",
    停止充电:"Stop charging",
    确认清空数据:"Confirm the clear data?",
    数据删除后将无法恢复请谨慎操作:"After the data is deleted, it will not be able to recover, please handle it carefully?",
    处理中:"Processing...",
    名称:"name",
    复制:"copy",
    复制成功:"Replication successfully",
    个人中心:"User center",
    报警设置:"Alarm Settings",
    时间设置:"time setting",
    多项设置:"Multiple settings",
    账号设置:"Account Settings",
    账号注销:"Account Delete",
    认证结果:"Authentication",
    密码修改:"change Password",
    关于我们:"about Us",
    设备操作:"Device operation",
    通知列表:"Notification list",
    SIM卡查询:"SIM card inquiry",
    支付:"Pay",
    支付宝:"Ali Pay",
    发送指令:"Send command",
    说明:"illustrate",
    开发认证:"Develop certification",

}
export default enUs;
