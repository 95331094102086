import Vue from 'vue';
import Router from 'vue-router';
import i18n from "./main"
Vue.use(Router);

const routes = [
  // {
  //   path: '/',
  //   redirect: '/setting/index',
  //   meta: {
  //     title:'loading...',
  //     keepAlive: true
  //   }
  // },
  {
    name: 'setting/index',
    component: () => import('./view/setting'),
    meta: {
      title:'loading...',
      keepAlive: true
    }
  },{
    name: 'setting/index/enjiaaier',
    component: () => import('./view/setting/index_enjiaaier'),
    meta: {
      title:'loading...',
      keepAlive: true
    }
  },{
    name: 'setting/alarm',
    component: () => import('./view/setting/alarm'),
    meta: {
      title: 'title'
    }
  },{
    name: 'setting/time',
    component: () => import('./view/setting/time/index'),
    meta: {
      title: 'title'
    }
  },{
    name: 'setting/time2',
    component: () => import('./view/setting/time/index2'),
    meta: {
      title:'title'
    }
  }
  ,{
    name: 'setting/multiple',
    component: () => import('./view/setting/multiple'),
    meta: {
      title:'title'
    }
  },{
    name: 'setting/switchvalue',
    component: () => import('./view/setting/switchvalue'),
    meta: {
      title:'title'
    }
  },
  {
    name: 'order/index',
    component: () => import('./view/order'),
    meta: {
      title:'loading...',
      keepAlive: true
    }
  },
  {
    name: 'user/setting',
    component: () => import('./view/user/setting'),
    meta: {
      title:'loading...',
      keepAlive: true
    }
  },
  {
    name: 'user/index',
    component: () => import('./view/user/index'),
    meta: {
      title:'loading...',
      keepAlive: true
    }
  },{
    name: 'user/accountoff',
    component: () => import('./view/user/accountoff/index'),
    meta: {
      title:'loading...',
      keepAlive: true
    }
  },
  {
    name: 'user/index/enjiaaier',
    component: () => import('./view/user/index_enjiaaier'),
    meta: {
      title:'loading...',
      keepAlive: true
    }
  },
  {
    name: 'user/auth/result',
    component: () => import('./view/user/auth'),
    meta: {
      title:'loading...',
      keepAlive: true
    }
  },
  {
    name: 'user/password/modify',
    component: () => import('./view/user/password/modify'),
    meta: {
      title:'loading...',
      keepAlive: true
    }
  },
  {
    name: 'system/about',
    component: () => import('./view/system/about'),
    meta: {
      title:'loading...',
      keepAlive: true
    }
  },
  {
    name: 'device/index',
    component: () => import('./view/device/index'),
    meta: {
      title:'loading...',
      keepAlive: true
    }
  },
  {
    name: 'device/list',
    component: () => import('./view/device/list'),
    meta: {
      title:'loading...',
      keepAlive: true
    }
  },
  {
    name: 'device/index/enjiaaier',
    component: () => import('./view/device/index_enjiaaier'),
    meta: {
      title:'loading...',
      keepAlive: true
    }
  },
  {
    name: 'device/info/index',
    component: () => import('./view/device/info/index'),
    meta: {
      title:'loading...',
      keepAlive: true
    }
  },
  {
    name: 'device/qr',
    component: () => import('./view/device/qr'),
    meta: {
      title:'设备信息',
      keepAlive: false
    }
  },
  {
    name: 'message/alarm/index',
    component: () => import('./view/message/alarm/index'),
    meta: {
      title:'loading...',
      keepAlive: false
    }
  },
  {
    name: 'test/jsinterfaces',
    component: () => import('./view/test/jsinterfaces'),
    meta: {
      title: 'APP接口测试',
      keepAlive: true
    }
  },
  {
    name: 'simcard/index',
    component: () => import('./view/simcard/index'),
    meta: {
      title:'loading...',
      keepAlive: true
    }
  },
  {
    name: 'simcard/index2',
    component: () => import('./view/simcard/index2'),
    meta: {
      title:'loading...',
      keepAlive: true
    }
  },
  {
    name: 'pay/index',
    component: () => import('./view/pay/index'),
    meta: {
      title:'loading...',
      keepAlive: true
    }
  },{
    name: 'pay/phone_alarm',
    component: () => import('./view/pay/phone_alarm'),
    meta: {
      title:'loading...',
      keepAlive: true
    }
  },
  {
    name: 'phonealarmrules/index',
    component: () => import('./view/phonealarmrules/index'),
    meta: {
      title:'电话报警规则',
      keepAlive: false
    }
  },
  {
    name: 'phonealarmrules/edit',
    component: () => import('./view/phonealarmrules/edit'),
    meta: {
      title:'电话报警规则设置',
      keepAlive: false
    }
  }
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ mode:"history",routes });

router.beforeEach((to, from, next) => {
  window.console.log("locale",i18n.locale)
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = i18n.t(title);
  }
  next();
});

export {
  router
};
