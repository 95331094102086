const imeiStorageKey = "_imei";
import {setToken} from "@/util/auth";
import { Locale } from 'vant';
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';
import zhCN from 'vant/es/locale/lang/zh-CN';
export function getImei() {
    return sessionStorage.getItem(imeiStorageKey)
}
export function setImei(imei) {
    return sessionStorage.setItem(imeiStorageKey,imei)
}

const saasIdStorageKey = "_saasId";
export function getSaasId() {
    return sessionStorage.getItem(saasIdStorageKey)
}
export function setSaasId(saasId) {
    return sessionStorage.setItem(saasIdStorageKey,saasId)
}

const languageStorageKey = "_language";
export function getLanguage() {
    return localStorage.getItem(languageStorageKey)
}
export function setLanguage(value) {
    return localStorage.setItem(languageStorageKey,value)
}
export function readCommonParam(that){
    let routeQuery = that.$route.query
    if(routeQuery._token){
        setToken(routeQuery._token)
    }

    let imei = routeQuery._imei;
    if(imei)
        setImei(imei)
    let saasId = routeQuery._saasId;
    if(saasId)
        setSaasId(saasId)
    let language = routeQuery._language;
    window.console.log("language",language,that.$i18n.locale)
    if(language){
        setLanguage(language)
    }else{
        language = getLanguage();
    }
    if(language=="en" || language == "es"){
        that.$i18n.locale = "enUS"
        Locale.use('en-US', enUS);
    }else{
        that.$i18n.locale = "zhCN"
        Locale.use('zh-CN', zhCN);

    }


}
