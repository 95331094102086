const zhCN = {
    当前设备:"当前设备",
    未命名设备:"未命名设备",
    设备列表:"设备列表",
    设备分享:"设备分享",
    设备续费:"设备续费",
    流量查询:"流量查询",
    电子围栏:"电子围栏",
    账号认证:"账号认证",
    语言切换:"语言切换",
    系统设置:"系统设置",
    注销账号:"注销账号",
    常见问题:"常见问题",
    当前版本:"当前版本",
    退出登录:"退出登录",
    设备信息:"设备信息",
    设备设置:"设备设置",
    设备指令:"设备指令",
    设备名称或IMEI:"设备名称或IMEI",
    搜索:"搜索",
    分组筛选:"分组筛选",
    请选择设备分组:"请选择设备分组",
    位置全览:"位置全览",
    左滑设备信息操作设备:"左滑设备信息操作设备",
    没有更多了:"没有更多了",
    解绑:"解绑",
    编辑:"编辑",
    取消:"取消",
    请选择分组:"请选择分组",
    确认:"确认",
    暂无数据:"暂无数据",
    操作确认:"操作确认",
    确认解绑此设备:"确认解绑此设备",
    在线:"在线",
    离线:"离线",
    停留:"停留",
    在线运动中:"在线 运动中",
    电量:"电量",
    全部:"全部",
    数据更新成功:"数据更新成功",
    确认修改:"确认修改",
    消息推送:"消息推送",
    修改密码:"修改密码",
    旧密码:"旧密码",
    请输入旧密码未设置密码可留空:"请输入旧密码，未设置密码可留空",
    数字字母或字符:"6-12位数字、字母或字符",
    新密码:"新密码",
    请填写新密码:"请填写新密码",
    确认密码:"确认密码",
    请再次输入密码:"请再次输入密码",
    请填写确认密码:"请填写确认密码",
    提交:"提交",
    密码应是数字字母或字符:"密码应是6-12位数字、字母或字符",
    两次输入的密码不一致请检查重试:"两次输入的密码不一致，请检查重试",
    密码修改成功:"密码修改成功",
    认证成功:"认证成功",
    认证失败:"认证失败",
    加载中:"加载中...",
    刷新结果:"刷新结果",
    注销账号提示:"注销账号提示",
    账号注销提示1:"账号注销是不可恢复的操作，且一旦注销会给您的售后维权及信息查询带来诸多不便，请谨慎操作。若您仍要注销，请您确保已经备份相关数据，并已充分阅读，理解并同意以下事项：",
    账号注销提示2:"您申请注销的账号需要先解绑所有设备",
    账号注销提示3:"账号注销后，您的本平台服务协议宣告终止，本平台将停止为您提供服务，您的账号因此可能产生如下后果：",
    账号注销提示4:"1.当前账号被收回，您将无法再登录、使用本账号。",
    账号注销提示5:"2.您的账户信息、历史数据将被删除或匿名化处理，您无法再访问、查询或下载、复制。但是，仍将在法律法规允许范围继续保存一段时间。",
    账号注销提示6:"此外，注销账户并不意味着注销前的账户行为或相关责任得到豁免或减轻；同时，因您未妥善处理相关事宜即选择注销而给您或相关联系人、第三方等造成的损失，亦需由您自行承担。",
    账号注销提示7:"我已阅读账号注销提示，开始注销",
    账号注销成功:"账号注销成功",
    用户使用协议:"用户使用协议",
    隐私保护协议:"隐私保护协议",
    结束时间:"结束时间",
    选择时间:"选择时间",
    开始时间:"开始时间",
    开关:"开关",
    重复:"重复",
    请选择重复时间:"请选择重复时间",
    周一:"周一",
    周二:"周二",
    周三:"周三",
    周四:"周四",
    周五:"周五",
    周六:"周六",
    周日:"周日",
    请输入:"请输入",
    设备名称:"设备名称",
    支付项目:"支付项目",
    微信支付:"微信支付",
    支付成功:"支付成功",
    支付失败:"支付失败",
    请选择支付项:"请选择支付项",
    支付确认:"支付确认",
    订单支付成功:"订单支付成功? ",
    发送中:"发送中...",
    发送成功:"发送成功",
    指令发送成功:"指令发送成功",
    重启:"重启",
    显示类型:"显示类型",
    请选择显示类型:"请选择显示类型",
    清空:"清空",
    下拉列表刷新数据左滑通知信息删除:"下拉列表刷新数据，左滑通知信息删除",
    删除:"删除",
    SOS报警:"SOS报警",
    围栏报警:"围栏报警",
    位移报警:"位移报警",
    静止报警:"静止报警",
    低电报警:"低电报警",
    震动报警:"震动报警",
    超速报警:"超速报警",
    上线报警:"上线报警",
    离线报警:"离线报警",
    开始充电:"开始充电",
    停止充电:"停止充电",
    确认清空数据:"确认清空数据?",
    数据删除后将无法恢复请谨慎操作:"数据删除后将无法恢复,请谨慎操作?",
    处理中:"处理中...",
    名称:"名称",
    复制:"复制",
    复制成功:"复制成功",
    个人中心:"个人中心",
    报警设置:"报警设置",
    时间设置:"时间设置",
    多项设置:"多项设置",
    账号设置:"账号设置",
    账号注销:"账号注销",
    认证结果:"认证结果",
    密码修改:"密码修改",
    关于我们:"关于我们",
    设备操作:"设备操作",
    通知列表:"通知列表",
    SIM卡查询:"SIM卡查询",
    支付:"支付",
}
export default zhCN;
