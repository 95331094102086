import 'amfe-flexible';
import Vue from 'vue';
import App from './App';
import { router } from './router';
import "./assets/iconfont/iconfont.css"
import "./css/global.css"
import VueI18n from 'vue-i18n'
import zhCn from "./i18n/zhCN"
import enUS from "./i18n/enUS"

//引入桥接文件
import Bridge from './util/bridge'
import {getLanguage} from "./util/session-storage-utils";
Vue.prototype.$bridge = Bridge
window.$bridge = Bridge
Vue.use(VueI18n)
let language = getLanguage();
if(language=="en" || language == "es"){
  language = "enUS"
}else{
  language = "zhCN"

}

const i18n = new VueI18n({
  locale: language, // 设置语言环境...
  messages:{zhCn,enUS}
});
export default i18n;
// Vue.prototype.$i18n = i18n
Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  el: '#app',
  render: h => h(App)
});
